export default async (fileName, contentStreamReference, pageNumber = 1, contentType = 'application/pdf') => {
    const arrayBuffer = await contentStreamReference.arrayBuffer();
    const blob = new Blob([arrayBuffer], {type: contentType});
    const url = URL.createObjectURL(blob);

    const iframe = document.createElement('iframe');
    iframe.src = url + "#page=" + pageNumber;
    iframe.width = '100%';
    iframe.height = '100%';
    iframe.style.border = 'none';

    const newWindow = window.open('', '_blank');
    newWindow.document.body.appendChild(iframe);
    newWindow.document.title = fileName;
};
