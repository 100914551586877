import jquery from 'jquery';

function getFocusableClass(element) {
    for (let i = 0; i < element.classList.length; i++) {
        if (element.classList[i].startsWith('focusable-')) {
            return element.classList[i];
        }
    }

    return null;
}

/**
 * Moves the focus to the previous focusable element in the DOM if one exists.
 *
 * The function identifies the current active element and determines its
 * associated focusable class. If such a class is found, it retrieves all
 * elements sharing this focusable class. It then finds the index of the
 * current active element within these focusable elements. If there is a
 * preceding element, the function shifts the focus to this previous element.
 *
 * The jQuery library is used for DOM element selection and manipulation.
 *
 * @function
 * @name focusPreviousElement
 */
export default () => {
    let element = document.activeElement;
    console.log(element);
    let focusableClass = getFocusableClass(element);
    console.log(focusableClass);
    if (!focusableClass) {
        console.log('not focused 1');
        const $parent = jquery(element).parents('[class*=focusable-]');
        if ($parent.length > 0) {
            element = $parent.get(0);
            focusableClass = getFocusableClass(element);
        }
    }

    console.log(focusableClass);
    if (!focusableClass) {
        console.log('not focused 2');
        return;
    }

    const $focusableElements = jquery(`.${focusableClass}`);
    const currentIndex = $focusableElements.index(element);
    if (currentIndex <= 0) {
        console.log('no previous');
        return;
    }

    const $previousElement = jquery($focusableElements.get(currentIndex - 1));
    console.log($previousElement);
    if ($previousElement.is('input,[contenteditable]')) {
        $previousElement.get(0).focus();
    } else {
        $previousElement.find('input,[contenteditable]').get(0).focus();
    }
}
