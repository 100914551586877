export default (element, data) => {
    if (!element) {
        return;
    }

    if (data === null || data === undefined) {
        data = '';
    }

    if (element._x_model) {
        element._x_model.set(data);
    } else {
        element.innerHTML = data;
    }
};
