import isInput from "../../utilities/isInput.js";
import {isPlaceholderActive, togglePlaceholder} from "./placeholder.js";

/**
 * Extracts and cleans the inner HTML of a given element by removing tags and encoding certain elements.
 * If the first child of the element is a text node, it looks for <br> tags.
 * Otherwise, it looks for closing tags of the first child's node name.
 * It preserves line breaks by encoding them as newline characters.
 *
 * @param {HTMLElement} element - The DOM element from which to extract and clean inner HTML.
 * @returns {string} The cleaned inner text of the given element.
 */
const getCleanInnerHtml = (element) => {
    if (!element.firstChild) {
        return element.innerHTML;
    }

    let firstTag = element.firstChild.nodeName;
    let keyTag = new RegExp(
        firstTag === '#text' ? '<br' : '</' + firstTag,
        'i'
    );
    let tmp = document.createElement('p');
    tmp.innerHTML = element.innerHTML
        .replace(/<[^>]+>/g, (m) => (keyTag.test(m) ? '{ß®}' : ''))
        .replace(/{ß®}$/, '');
    return tmp.innerText.replace(/{ß®}/g, '\n');
};

/**
 * Retrieves the value from a given HTML element.
 * For input elements, it returns the value property.
 * For other elements, it returns the innerHTML property.
 *
 * @param {HTMLElement} element - The HTML element from which to retrieve the value.
 * @returns {string} The value or innerHTML of the element.
 */
export const getValue = (element) => {
    if (isInput(element)) {
        return element.value;
    }

    if (isPlaceholderActive(element)) {
        return '';
    }

    return getCleanInnerHtml(element);
};

/**
 * Sets the value of a given element. If the element is an input, it sets the input's value.
 * Otherwise, it sets the inner text of the element.
 *
 * @param {HTMLElement} element - The HTML element to set the value for.
 * @param {string} value - The value to set on the element.
 */
export const setValue = (element, value) => {
    if (isInput(element)) {
        element.value = value;
    } else {
        element.innerText = value;
    }

    togglePlaceholder(element);
}
