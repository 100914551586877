﻿import Alpine from 'alpinejs';
import alpineFocus from '@alpinejs/focus'

import './autocomplete.js';
import './contenteditable.js';
import './datepicker.js';
import './quill.js';

Alpine.plugin(alpineFocus);
Alpine.start();
window.Alpine = Alpine;
