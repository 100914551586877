import Alpine from 'alpinejs';
import jQuery from 'jquery';
import callDotNet from "../blazor/callDotNet.js";
import delay from "../utilities/delay.js";
import isElementInView from "../utilities/isElementInView.js";
import debounce from '../utilities/debounce.js';
import addElementsNextTo from "../utilities/addElementsNextTo.js";
import isInput from "../utilities/isInput.js";
import {removePlaceholder, togglePlaceholder} from "./utlities/placeholder.js";
import {getValue, setValue} from "./utlities/value.js";
import {getContentClasses, selectContent} from "./utlities/content.js";

Alpine.directive('autocomplete', (element, {expression, modifiers}, {cleanup}) => {
    let skipPopupOpen = false;

    /**
     * Handles click events on the body of the document.
     * If the target of the event is not within the parent element of 'element', the popup is closed and the element is blurred.
     *
     * @param {Event} event - The click event that triggered the handler.
     */
    const bodyClick = async (event) => {
        const $parent = jQuery(element).parent();
        if ($parent.find(event.target).length === 0) {
            closePopup();
            blur();

            // Close all other popups in case any stayed opened by mistake.
            jQuery('.ra-autocomplete-popup').each((index, popup) => {
                popup.removeEventListener('mousemove', popupMouseMove);
                popup.removeEventListener('click', popupClick);
                jQuery(popup).parent().remove();
            })
        }
    };

    /**
     * Gets the popup element if it exists.
     * @returns {HTMLElement}
     */
    const getPopupElement = () => {
        return document.getElementById(element.dataset.popupId);
    };

    /**
     * Handles the mouse move event on a popup menu item.
     *
     * This function detects the list item (`li`) where the mouse event occurred,
     * retrieves the popup element, finds the index of the `li` within its siblings,
     * and highlights the corresponding option based on the detected index.
     *
     * @param {Object} event - The mouse event object.
     */
    const popupMouseMove = (event) => {
        const li = jQuery(event.target).closest('li').get(0);
        if (li) {
            const popup = getPopupElement();
            if (!popup) {
                return;
            }

            const index = jQuery(popup).find('ul').children('li').index(li);
            highlightOption(index);
        }
    };

    /**
     * Handles the click event on a popup element.
     *
     * This function is triggered when a user clicks on a list item (li) within a specified popup.
     * It determines which list item was clicked, finds the corresponding popup element, and selects
     * the option based on the index of the clicked list item. Additionally, it sets a flag to skip
     * opening the popup and focuses on the associated element.
     *
     * @param {Event} event - The click event object.
     */
    const popupClick = (event) => {
        const li = jQuery(event.target).closest('li').get(0);
        if (li) {
            const popup = getPopupElement();
            if (!popup) {
                return;
            }

            const index = jQuery(popup).find('ul').children('li').index(li);
            selectOption(index);
            skipPopupOpen = true;
            element.focus();
        }
    };

    /**
     * Determines if the popup is currently opened.
     *
     * This function checks the existence of a popup element in the DOM
     * and returns a boolean indicating whether the popup is open.
     *
     * @returns {boolean} True if the popup is opened, false otherwise.
     */
    const isPopupOpened = () => {
        return !!getPopupElement();
    };

    /**
     * Asynchronously opens a popup with options fetched from a .NET method.
     *
     * The function performs the following steps:
     * 1. Calls a .NET method to retrieve a list of options.
     * 2. If no options are returned or if a popup is already open, it exits early.
     * 3. Closes the current popup if present, unless the `skipPopupOpen` flag is set.
     * 4. Constructs the HTML structure for the popup containing the fetched options.
     * 5. Inserts the constructed popup HTML into the DOM next to the specified element.
     * 6. Adds required event listeners to the popup for interactions like mouse movement and clicks.
     * 7. Stores a reference to the popup's DOM element in the specified element's jQuery data.
     * 8. Adds a click event listener to the window to handle clicks outside the popup.
     * 9. Sorts the options within the popup.
     *
     * @returns {Promise<void>}
     */
    const openPopup = async () => {
        if (modifiers.includes('select')) {
            selectContent(element);
        }

        const options = await callDotNet(element, "GetOptions");
        if (options.length === 0) {
            return;
        }

        if (isPopupOpened()) {
            return;
        }

        closePopup();
        if (skipPopupOpen) {
            skipPopupOpen = false;
            return;
        }

        let popup = `<ul class="ra-autocomplete-popup bg-white !border border-neutral-200/70 focus:outline-none max-h-[240px] my-[2px] overflow-auto py-1 ring-1 ring-black ring-opacity-5 rounded-b-lg rounded-md shadow-md text-neutral-700 text-sm w-60 z-50">`;
        for (let index = 0; index < options.length; index++) {
            const option = options[index];
            const labelAttribute = option.label.replace('"', '\\"');

            popup += `
                <li class="cursor-pointer duration-200 flex flex-wrap gap-x-2 h-full items-center px-3 py-2 relative select-none text-gray-700 transition-colors"
                    data-value="${option.value.replace('"', '\\"')}"
                    data-label="${labelAttribute.toLowerCase()}"
                    title="${labelAttribute}">
                    <span class="font-medium truncate">
                        ${option.label}
                    </span>
                    ${option.extraContent || ''}
                </li>`;
        }

        popup += `</ul>`;

        const popupElement = addElementsNextTo(element, popup);

        await delay();

        popupElement.addEventListener('mousemove', popupMouseMove);
        popupElement.addEventListener('click', popupClick);
        element.dataset.popupId = popupElement.id;

        window.addEventListener('click', bodyClick);
        await sortOptions();
    };
    const openPopupDebounce = debounce(openPopup, 100);

    /**
     * A function that closes and cleans up listeners for a popup element.
     *
     * This function performs the following operations:
     * - Retrieves the popup element using the `getPopupElement` function.
     * - If the popup element exists:
     *   - Removes the 'mousemove' event listener from the popup element.
     *   - Removes the 'click' event listener from the popup element.
     *   - Removes the popup element from the DOM using jQuery.
     * - Removes the 'click' event listener from the window object.
     *
     * It ensures that the popup element is properly cleaned up from the DOM
     * and that all associated event listeners are detached, preventing memory leaks.
     */
    const closePopup = () => {
        const popup = getPopupElement();
        if (popup) {
            popup.removeEventListener('mousemove', popupMouseMove);
            popup.removeEventListener('click', popupClick);
            jQuery(popup).remove();
        }

        window.removeEventListener('click', bodyClick);
    };

    /**
     * Retrieves the zero-based index of the currently highlighted option in a popup menu.
     *
     * This function searches for a popup menu element and looks for a list item (`<li>`)
     * that has the `bg-neutral-100` CSS class, indicating it is the highlighted option.
     * It returns the index of this element within its parent list (`<ul>`).
     * If the popup menu or the highlighted option cannot be found, it returns -1.
     *
     * @returns {number} The zero-based index of the highlighted option, or -1 if not found.
     */
    const getHighlightedOptionIndex = () => {
        const popup = getPopupElement();
        if (!popup) {
            return -1;
        }

        const $ul = jQuery(popup).find('ul');
        return $ul.children('li').index($ul.children('li.bg-neutral-100'));
    };

    /**
     * Highlights a specific option in a popup menu by its index.
     *
     * This function interacts with a popup menu, finding a list within it and
     * applying specific CSS classes to highlight an item at the given index.
     * It also ensures that the highlighted item is scrolled into view if it is not currently visible.
     *
     * @param {number} index - The index of the item to highlight. If the index is out of bounds, the last item will be selected.
     * @returns {number|undefined} - Returns -1 if no popup element is found. Otherwise, returns undefined.
     */
    const highlightOption = (index) => {
        const popup = getPopupElement();
        if (!popup) {
            return -1;
        }

        const $ul = jQuery(popup).find('ul');
        const $items = $ul.find('li');
        if (index >= $items.length) {
            index = $items.length - 1;
        }

        $ul.children('li.bg-neutral-100').removeClass(['bg-neutral-100', 'text-gray-900']);
        if (index < 0) {
            return;
        }

        $items.eq(index).addClass(['bg-neutral-100', 'text-gray-900']);
        const ul = $ul.get(0);
        const highlightedLi = $items.get(index);
        if (ul && highlightedLi) {
            const visible = isElementInView(highlightedLi, ul);
            if (!visible) {
                const newScrollPos = (highlightedLi.offsetTop + highlightedLi.offsetHeight) - ul.offsetHeight;
                ul.scrollTop = newScrollPos > 0 ? newScrollPos : 0;
            }
        }
    };

    /**
     * Selects an option from a popup menu based on the specified index.
     *
     * @param {number} index - The zero-based index of the option to be selected.
     */
    const selectOption = (index) => {
        const popup = getPopupElement();
        if (!popup) {
            return;
        }

        const $ul = jQuery(popup).find('ul');
        const $item = $ul.children('li').eq(index);
        if (!$item) {
            return;
        }

        const dataValue = $item.data('value').toString();
        const dataLabel = $item.data('value').toString();

        closePopup();
        callDotNet(element, "OnBlur", dataValue);
        setValue(element, dataLabel);
    };

    /**
     * Asynchronously sorts a list of options within a popup element based on the value retrieved
     * from a given input element. The sorting prioritizes options that start with or contain
     * the search value (case-insensitive).
     *
     * This function:
     * - Retrieves the current value from the input element.
     * - Converts the search value to lowercase for a case-insensitive comparison.
     * - Finds and sorts the list items within the popup element's unordered list (<ul>).
     * - Prioritizes list items that start with or contain the search value.
     * - Detaches and re-appends the sorted list items to the <ul>.
     * - Highlights the first matching option if any matches are found; otherwise, no option is highlighted.
     *
     * @returns {Promise<void>} Resolves when the sorting, detaching, and highlighting processes are complete.
     */
    const sortOptions = async () => {
        const value = getValue(element);
        const search = value.toLowerCase();
        const popup = getPopupElement();
        if (!popup) {
            return;
        }

        const $ul = jQuery(popup).find('ul');
        const $items = $ul.children('li');
        let hasMatch = false;

        const sortedItems = $items.toArray().sort(function (a, b) {
            const aValue = $(a).data('label').toString();
            const bValue = $(b).data('label').toString();
            if (search) {
                const aStartsWith = aValue.startsWith(search);
                const bStartsWith = bValue.startsWith(search);
                if (aStartsWith && bStartsWith) {
                    hasMatch = true;
                    return aValue > bValue ? 1 : -1;
                } else if (aStartsWith) {
                    hasMatch = true;
                    return -1;
                } else if (bStartsWith) {
                    hasMatch = true;
                    return 1;
                }

                const aIndexOf = aValue.indexOf(search);
                const bIndexOf = bValue.indexOf(search);
                if (aIndexOf !== -1 && bIndexOf !== -1) {
                    hasMatch = true;
                    return aIndexOf > bIndexOf ? 1 : -1;
                } else if (aIndexOf !== -1) {
                    hasMatch = true;
                    return -1;
                } else if (bIndexOf !== -1) {
                    hasMatch = true;
                    return 1;
                }
            }

            return aValue > bValue ? 1 : -1;
        });

        $items.detach();
        $ul.append(sortedItems);
        await delay();
        highlightOption(hasMatch ? 0 : -1);
    };

    /**
     * When an input happens on the element, we need to make sure that the underlying alpine model is updated.
     * For autocomplete, we also sort the list of options.
     *
     * @returns {Promise<void>} Resolves when the processes are complete.
     */
    const input = async () => {
        await sortOptions();
    };

    /**
     * Handles the paste event by preventing the default behavior,
     * retrieving the text from the clipboard, and inserting it
     * at the current selection point within the document.
     *
     * @param {ClipboardEvent} event - The clipboard event triggered
     * by a paste action.
     */
    const paste = (event) => {
        event.preventDefault();

        const paste = (event.clipboardData || window.clipboardData).getData("text");
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        selection.collapseToEnd();

        element.dispatchEvent(new Event('input'));
    };

    /**
     * Handles keydown events for a specific element, providing custom behavior
     * for Tab, ArrowUp, ArrowDown, and Enter keys.
     *
     * - For "Tab" key: Closes a popup and blurs the current element.
     * - For "ArrowUp" key: Prevents the default action, highlights the previous option in a list.
     * - For "ArrowDown" key: Prevents the default action, highlights the next option in a list.
     * - For "Enter" key: Depending on the state of a popup, either performs a selection action
     *                    or blurs the element and submits the closest form.
     *
     * @param {KeyboardEvent} event - The keyboard event object.
     */
    const keydown = (event) => {
        if (event.key === "Tab") {
            closePopup();
            blur();
        }

        if (event.key === 'ArrowUp') {
            event.preventDefault();
            const index = getHighlightedOptionIndex();
            highlightOption(index >= 1 ? index - 1 : 0);
        }

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            const index = getHighlightedOptionIndex();
            highlightOption(index >= 0 ? index + 1 : 0);
        }

        if (event.key === "Enter") {
            if (isPopupOpened()) {
                const index = getHighlightedOptionIndex();
                if (index !== -1) {
                    selectOption(getHighlightedOptionIndex());
                } else {
                    closePopup();
                }
            } else {
                element.blur();

                // If the autocomplete is inside a form, we want to submit the form now.
                if (isInput(element)) {
                    setTimeout(function () {
                        const $submit = $(element).closest('form').find('button[type=submit]');
                        if ($submit.length >= 1) {
                            $submit.click();
                        }
                    }, 100);
                }
            }

            event.preventDefault();
        }

        event.stopPropagation();
    };

    /**
     * This variable defines an arrow function named `click`.
     * When invoked, it triggers `openPopupDebounce` function.
     *
     * The debounce mechanism ensures that the `openPopupDebounce` function
     * is not called too frequently, thus improving performance and enhancing user experience.
     */
    const click = () => {
        openPopupDebounce();
    };

    /**
     * Event handler for when the element loses focus.
     *
     * This function performs the following actions:
     * - Resets the `minWidth` style of the element.
     * - Toggles the placeholder visibility.
     * - Removes event listeners for `blur`, `input`, `paste`, `click`, and `keydown` events.
     * - Calls a .NET method named `OnBlur` with the current value of the element.
     */
    const blur = () => {
        element.style.minWidth = null;
        togglePlaceholder(element);

        element.removeEventListener('blur', blur);
        element.removeEventListener('input', input);
        element.removeEventListener('paste', paste);
        element.removeEventListener('click', click);
        element.removeEventListener('keydown', keydown);

        callDotNet(element, "OnBlur", getValue(element));
    };

    /**
     * A function to set focus on an element and add necessary event listeners.
     *
     * Upon invocation, this function performs the following:
     * 1. Calculates the bounding rectangle of the element.
     * 2. Sets the element's minimum width to its current width.
     * 3. Removes any placeholder from the element.
     * 4. Adds event listeners for 'blur', 'input', 'paste', 'click', and 'keydown' events.
     * 5. Calls a debounced function to open a popup.
     */
    const focus = () => {
        const rect = element.getBoundingClientRect();
        element.style.minWidth = rect.width + 'px';
        removePlaceholder(element);

        element.addEventListener('blur', blur);
        element.addEventListener('input', input);
        element.addEventListener('paste', paste);
        element.addEventListener('click', click);
        element.addEventListener('keydown', keydown);

        openPopupDebounce();
    };

    /**
     * Initializes the AutoComplete feature for the specified element.
     *
     * This function sets up the necessary event listeners and handlers to provide
     * autocomplete functionality. It binds to specific input events and fetches
     * suggestions from a predefined source or a dynamic data provider.
     *
     * @function
     * @name raAutoCompleteInitialize
     * @memberof element
     */
    element.raAutoCompleteInitialize = (type) => {
        element.removeEventListener('focus', focus);

        // Only add event listeners if the field should be editable.
        if (type !== 'readonly') {
            element.tabIndex = 0;
            element.addEventListener('focus', focus);

            if (isInput(element)) {
                element.disabled = false;
            } else {
                element.className = getContentClasses(modifiers);
                element.setAttribute("contenteditable", true);
            }
        } else {
            element.tabIndex = -1;

            if (isInput(element)) {
                element.disabled = true;
            } else {
                element.setAttribute("contenteditable", false);
            }
        }

        togglePlaceholder(element);
    };

    // Initialize
    element.raAutoCompleteInitialize(expression);

    // Cleanup all event listeners when the field is removed from the DOM.
    cleanup(() => {
        closePopup();
        element.removeEventListener('focus', focus);
        element.removeEventListener('blur', blur);
        element.removeEventListener('input', input);
        element.removeEventListener('paste', paste);
        element.removeEventListener('click', click);
        element.removeEventListener('keydown', keydown);
    });
});
