import isInput from "../../utilities/isInput.js";
import {getValue} from "./value.js";

/**
 * Checks if a placeholder is active for the given element.
 *
 * The function determines the placeholder status in two scenarios:
 * - For input-related elements, it checks if the input value is empty.
 * - For non-input elements, it checks if the element has the 'italic' class.
 *
 * @param {HTMLElement} element - The DOM element to check the placeholder status for.
 * @returns {boolean} - Returns true if the placeholder is active, otherwise false.
 */
export const isPlaceholderActive = (element) => {
    if(isInput(element)) {
        return !element.value;
    }

    return element.innerHTML === getPlaceholder(element);
};

/**
 * Retrieves the placeholder text from a given element.
 * If the element is an input, it extracts the placeholder attribute.
 * Otherwise, it retrieves the placeholder from the element's data attribute.
 *
 * @param {HTMLElement} element - The DOM element from which to get the placeholder.
 * @returns {string} The placeholder text from the element.
 */
export const getPlaceholder = (element) => {
    if (!element) {
        return '';
    }

    if (isInput(element)) {
        return element.placeholder;
    }

    return element.dataset.placeholder;
};

/**
 * Removes the placeholder styling from a given element and sets its content.
 *
 * If the element is an input, the function exits without making changes.
 * For non-input elements, it removes specific CSS classes (`text-neutral-400`,
 * `italic`, `text-sm`) that are typically used to style placeholders.
 * Then, it sets the element's innerHTML to its value or an empty string if
 * the value is not defined.
 *
 * @param {HTMLElement} element - The element from which to remove the placeholder.
 */
export const removePlaceholder = (element) => {
    if (!element || isInput(element)) {
        return;
    }

    const value = getValue(element);
    element.classList.remove("text-neutral-400", "italic", "text-sm");
    element.innerHTML = value || '';
};

/**
 * Handles the toggle functionality of a placeholder for a given element.
 *
 * If the element has a value, it removes the placeholder.
 * Otherwise, it sets the placeholder for the element.
 * For input elements, it sets the placeholder attribute.
 * For non-input elements, it adds placeholder classes and sets the innerHTML.
 *
 * @param {HTMLElement} element - The element to toggle the placeholder for.
 */
export const togglePlaceholder = (element) => {
    if (!element) {
        return;
    }

    const value = getValue(element);
    if (value) {
        removePlaceholder(element);
        return;
    }

    const placeholder = getPlaceholder(element);
    if (!placeholder) {
        return;
    }

    if (isInput(element)) {
        element.placeholder = placeholder;
    } else {
        element.classList.add("text-neutral-400", "italic", "text-sm");
        element.innerHTML = placeholder;
    }
};
