﻿import generateId from "./generateId.js";

export default (target, element) => {
    if (!target) return;

    const newElement = document.createElement('div');
    newElement.classList.add('absolute');
    newElement.classList.add('z-[9999]');
    newElement.classList.add('invisible');
    newElement.id = generateId();
    $(newElement).append(element);
    document.body.appendChild(newElement);

    const targetRect = target.getBoundingClientRect();
    setTimeout(function () {
        const newElementBottomPos = targetRect.top + targetRect.height + newElement.offsetHeight;
        if (window.innerHeight < newElementBottomPos) {
            newElement.style.top = `${targetRect.top + window.scrollY - newElement.offsetHeight}px`;
        } else {
            newElement.style.top = `${targetRect.top + targetRect.height + window.scrollY}px`;
        }

        newElement.style.left = `${targetRect.left + window.scrollX}px`;
        newElement.classList.remove('invisible');
    }, 0);

    return newElement;
};
