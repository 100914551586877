/**
 * Asynchronously invokes a specified .NET method on a given element.
 *
 * This function calls the .NET method via an element's `_dotNetReference`. If the reference
 * is not present on the element, it logs a warning and exits.
 *
 * @param {Object} element - The element containing the .NET reference.
 * @param {string} method - The name of the .NET method to be invoked.
 * @param {...any} args - Additional arguments to be passed to the .NET method.
 * @returns {Promise<any>} - A promise that resolves with the result of the .NET method invocation.
 */
export default async (element, method, ...args) => {
    if (!element._dotNetReference) {
        console.warn(`Calling ${method}(). The dotNetReference is missing on the element.`);
        return;
    }

    return await element._dotNetReference.invokeMethodAsync.apply(element._dotNetReference, [
        method,
        ...args,
    ]);
};
