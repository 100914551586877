import callDotNet from "../blazor/callDotNet";

const keybinds = [];

document.addEventListener('keydown', (e) => {
    var value = '';
    if (e.ctrlKey) {
        value += "Ctrl+";
    }

    if (e.shiftKey) {
        value += "Shift+";
    }

    if (e.altKey) {
        value += "Alt+";
    }

    if (e.key.length === 1) {
        value += e.key.toUpperCase();
    } else {
        value += e.key;
    }

    for (var i = 0; i < keybinds.length; i++) {
        if (keybinds[i].keybind === value) {
            console.log(`Keybind detected: ${value}`);

            callDotNet(keybinds[i].element, "OnKeybind");
        }
    }
});

export const listenToKeybind = (element, keybind) => {
    keybinds.push({
        element,
        keybind,
    });
};

export const unlistenToKeybind = (element) => {
    const index = keybinds.findIndex(keybind => keybind.element === element);
    if (index >= 0) {
        keybinds.splice(index, 1);
    }
};
