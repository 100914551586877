import Sortable from 'sortablejs';

export default (id, group, sort, component) => {
    const sortable = new Sortable(document.getElementById(id), {
        animation: 200,
        group: group,
        filter: undefined,
        sort: sort,
        forceFallback: true,
        handle: undefined,
        onStart: async (event) => {
            await component.invokeMethodAsync('OnStart', event.oldIndex);
        },
        onUpdate: async (event) => {
            await component.invokeMethodAsync('OnUpdate', event.from.id, event.oldIndex, event.to.id, event.newIndex);
        },
        onRemove: async (event) => {
            await component.invokeMethodAsync('OnUpdate', event.from.id, event.oldIndex, event.to.id, event.newIndex);
        }
    });

    return {
        dispose() {
            sortable.destroy();
        }
    }
}
