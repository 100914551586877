/**
 * Generates a string of CSS classes for content editable elements based on the provided modifiers.
 *
 * @param {string[]} modifiers - An array of strings where each string is a modifier to adjust the resulting CSS classes.
 * @returns {string} A string of CSS class names concatenated based on the given modifiers.
 */
export const getContentClasses = (modifiers) => {
    let result = '-m-1 border-none focus:outline-none min-w-4 p-1 rounded-lg whitespace-pre-wrap duration-200 focus:ring-2 focus:ring-offset-0 focus:ring-cyan-400 transition-colors hover:bg-black/[.1]'

    if (modifiers.includes('select')) {
        result += ' cursor-pointer after:content-[\'\'] after:inline-block after:ml-3 after:w-[8px] after:h-[4px] after:border-t-[4px] after:border-x-[4px] after:border-x-transparent'
    }

    return result;
};

/**
 * Selects the content of a specified DOM element.
 *
 * This function selects the entire content of a specified DOM element
 * after a short delay using a timeout. It works for both modern browsers
 * using the `getSelection` and `createRange` methods, and for older
 * versions of Internet Explorer using the `createTextRange` method.
 *
 * @param {Element} element - The DOM element whose content is to be selected.
 */
export const selectContent = (element) => {
    setTimeout(function () {
        let sel, range;
        if (window.getSelection && document.createRange) {
            range = document.createRange();
            range.selectNodeContents(element);
            sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (document.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(div);
            range.select();
        }
    }, 1);
};
