function isFocusable(element) {
    return !element.disabled && element.tabIndex >= 0 && window.getComputedStyle(element).display !== 'none' && element.contentEditable !== 'false';
}

function retry(elementId, hasRetried) {
    if (hasRetried) {
        console.warn('Could not find focusable element on the second pass');
        return;
    }

    setTimeout(function () {
        focusFirstFocusableElement(elementId, true);
    }, 100);
}

function focusFirstFocusableElement(elementId, hasRetried) {
    const element = document.getElementById(elementId);
    if (!element) {
        retry(elementId, hasRetried);
        console.error('Element not found with the provided id:', elementId);
        return;
    }

    // Focus the element itself if it is focusable
    if (isFocusable(element)) {
        element.focus();
        return;
    }

    const focusableSelectors = 'input, button, [contenteditable], select, textarea, a[href], area[href], iframe, object, embed, [tabindex], [onclick], [onfocus]';

    // Get all focusable children
    const focusableElements = element.querySelectorAll(focusableSelectors);

    for (let focusableElement of focusableElements) {
        if (isFocusable(focusableElement)) {
            focusableElement.focus();
            return;
        }
    }

    retry(elementId, hasRetried);
    console.error('No focusable elements found within the provided element and the element itself is not focusable');
}

export default (elementId) => focusFirstFocusableElement(elementId);
