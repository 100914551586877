
export default (dotnetRef, element) => {
    if (!element) {
        return {};
    }

    const observedElements = window.ra.intersectionObservers.observedElements;

    const root = window.ra.findClosestScrollContainer(element);

    const show = async (node) => {
        if (!node || !node.id || node.nodeType !== Node.ELEMENT_NODE) {
            return;
        }

        node.classList.remove('out-viewport');
        node.classList.add('in-viewport');
        node.style.minHeight = '16px';

        if (!observedElements[node.id]) {
            observedElements[node.id] = {};
        }

        observedElements[node.id].lastShown = Date.now();

        await dotnetRef.invokeMethodAsync('IntersectionChanged', {
            isIntersecting: true,
            targetId: node.id,
        });
    };

    const hide = async (node) => {
        if (!node || !node.id || node.nodeType !== Node.ELEMENT_NODE) {
            return;
        }

        if (!observedElements[node.id]) {
            observedElements[node.id] = {};
        }

        if (observedElements[node.id].lastShown && Date.now() - observedElements[node.id].lastShown <= 1000) {
            return;
        }

        node.classList.remove('in-viewport');
        node.classList.add('out-viewport');

        observedElements[node.id].height = node.offsetHeight || 50;
        node.style.minHeight = observedElements[node.id].height + 'px';

        await dotnetRef.invokeMethodAsync('IntersectionChanged', {
            isIntersecting: false,
            targetId: node.id,
        });
    };

    const intersectionCallback = async (entries) => {
        for (let i = 0; i < entries.length; i++) {
            if (entries[i].isIntersecting) {
                await show(entries[i].target);
            } else {
                await hide(entries[i].target);
            }
        }
    };

    const intersectionObserver = new IntersectionObserver(intersectionCallback, {
        root: root,
        rootMargin: `600px 99999px 600px 99999px`,
        threshold: 0.0,
    });

    const observe = async (node) => {
        if (!node || !node.id || node.nodeType !== Node.ELEMENT_NODE) {
            return;
        }

        intersectionObserver.observe(node);
        if (observedElements[node.id]) {
            node.style.minHeight = observedElements[node.id].height + 'px';
        }
    };

    const unobserve = (node) => {
        if (!node || !node.id || node.nodeType !== Node.ELEMENT_NODE) {
            return;
        }

        intersectionObserver.unobserve(node);
    };

    const dispose = () => {
        intersectionObserver.disconnect();
    };

    return {
        observe,
        unobserve,
        dispose,
    };
};
